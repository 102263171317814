import { Dialog } from 'quasar'
import { getActivePinia } from 'pinia'

export default function (promptUser: boolean = false, showNotification = true) {
  const router = useRouter()

  const resetAllStores = () => {
    if (import.meta.client) {
      const activepinia = getActivePinia()
      if (activepinia) {
        Object.entries(activepinia.state.value).forEach(
          ([storeName, state]) => {
            const storeDefinition = defineStore(storeName, state)
            const store = storeDefinition(activepinia)
            store.$dispose()
          }
        )
      }
    }
  }

  const performReset = () => {
    const ftoken = useCookie('ftoken')
    const authorization_token = useCookie('authorization_token')

    resetAllStores()

    authorization_token.value = null
    ftoken.value = null

    if (showNotification)
      notifier({
        message:
          'Locally stored data is removed, page will refreshed in 6 seconds',
        type: 'info'
      })

    setTimeout(() => {
      router.push('/')
    }, 4000)
  }

  if (!promptUser) return performReset()

  Dialog.create({
    cancel: true,
    message: `
            Please note that if you are having issues with functionality and
            the issue persist after a doing force refresh, create a service desk
            issue using campfire
            `
  }).onOk(() => performReset())
}
